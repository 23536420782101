<template>
  <v-simple-table class="default-table">
    <template v-slot:default>
      <thead>
        <tr>
          <th rowspan="2" class="text-center">Skala PERFECT</th>
          <th
            class="default-input-cell dtrow"
            colspan="2"
            @click.prevent="$refs.date.show = true"
          >
            <LabelDateField
              ref="date"
              :value.sync="value.date"
              :disabled="disabled"
              placeholder="Wybierz datę badania..."
              flat
              hideDetails
              noMargins
              solo
            ></LabelDateField>
          </th>
        </tr>
        <tr>
          <th class="text-center">{{ pLabel }}</th>
          <th class="text-center">{{ lLabel }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="scale in scales" :key="scale.id">
          <td class="default-title-cell">{{ scale.desc }}</td>
          <TextAreaCell
            class="default-input-cell"
            :value.sync="value[scale.value].p"
            placeholder="Wpisz..."
            :disabled="disabled"
            :maxlength="100"
          ></TextAreaCell>
          <TextAreaCell
            class="default-input-cell"
            :value.sync="value[scale.value].g"
            placeholder="Wpisz..."
            :disabled="disabled"
            :maxlength="100"
          ></TextAreaCell>
        </tr>
        <tr>
          <th class="text-center">Uwagi</th>
          <TextAreaCell
            class="default-input-cell"
            colspan="3"
            :value.sync="value.comment"
            placeholder="Wpisz uwagi..."
            :disabled="disabled"
          ></TextAreaCell>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scales: [
        {
          id: 0,
          desc: "P - power - siła mięśni oceniania w skali Oxfordzkiej",
          value: "power",
        },
        {
          id: 1,
          desc: "E - endurance - wytrzymałość, ile czasu można utrzymać maksymalny skurcz mięśni",
          value: "endurance",
        },
        {
          id: 2,
          desc: "R - repetitions - ile skurczy z maksymalną siłą jest w stanie zrobić",
          value: "repetitions",
        },
        {
          id: 3,
          desc: "F - fast - szybkość - ile szybkich skurczy jest w stanie zrobić",
          value: "fast",
        },
        {
          id: 4,
          desc: "E - evaluation - czy występuje skurcz wszystkich mięśni czy tylko części, patrzymy na deficyt ruchu",
          value: "evaluation",
        },
        {
          id: 5,
          desc: "C - contraction - współpraca - czy jest współpraca dna miednicy z innymi mięśniami",
          value: "contraction",
        },
        {
          id: 6,
          desc: "T - timing - czas skurczu - czy występuje odruchowa aktywność mięśni np. podczas kaszlu",
          value: "timing",
        },
      ],
    };
  },
  computed: {
    pLabel() {
      return this.value.comparison
        ? "P - strona prawa"
        : "P - warstwa powierzchniowa";
    },
    lLabel() {
      return this.value.comparison ? "L - strona lewa" : "G - warstwa głęboka";
    },
  },
  components: {
    LabelDateField: () => import("@/components/LabelDateField"),
    TextAreaCell: () => import("@/components/tables/TextAreaCell"),
  },
  methods: {
    validateInput(value) {
      if (/^[0-5]$/.test(value)) {
        return [value];
      } else if (/^[0-5]/.test(value)) {
        return [value[0]];
      } else {
        return [''];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.default-table {
  border-collapse: collapse !important;
  border: 0.5px lightgray solid !important;
}
.default-title-cell {
  border-collapse: collapse !important;
  border: 0.5px lightgray solid !important;
  color: black !important;
  font-size: 14px !important;
  font-weight: bold !important;
}
.default-input-cell {
  border-collapse: collapse !important;
  border: 0.5px lightgray solid !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  min-width: 90px;
}
.default-input-cell ::v-deep input {
  text-align: center;
}
.default-table ::v-deep th {
  border-collapse: collapse !important;
  border: 0.5px lightgray solid !important;
  color: black !important;
  font-size: 14px !important;
}
.default-table ::v-deep tr:hover {
  background-color: transparent !important;
}
.dtrow {
  min-width: 125px !important;
}
</style>